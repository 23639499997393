import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { isEmpty, ObjectIsempty } from "../lib/isEmpty";
import { addNewToken } from "../actions/currency";
import { UseToken } from "../Hooks/useWeb3";
import Select from "react-select";
import toast from "react-hot-toast";
const initialFormValue = {
  name: "",
  address: "",
  symbol: "",
  decimals: "",
  chainId: "",
  logoURI: "",
};

const AddTokenModal = ({ show, handleClose, getTokens }) => {
  const [formvalue, setFormvalue] = useState({});
  const [errors, setErrors] = useState({});

  const options = [
    { value: 56, label: "56" },
    { value: 137, label: "137" },
  ];
  const addressRegex = "^(0x)[0-9A-Fa-f]{40}$";

  const handleChange = async (e) => {
    try {
      const { name, value } = e.target;
      // if (name == "address") {
      //   var pattern = new RegExp(addressRegex);
      //   if (!pattern.test(value)) {
      //     return;
      //   }
      // }
      let Formvalue = { ...formvalue, [name]: value };
      setFormvalue(Formvalue);

      if (name == "address") {
        const tokenDetails = await UseToken(
          selectedOption2.value,
          e.target.value
        );
        if (tokenDetails?.status == true) {
          Formvalue = { ...Formvalue, ["chainId"]: selectedOption2.value };
          Formvalue = {
            ...Formvalue,
            ["name"]: tokenDetails.data.tokenName,
          };
          Formvalue = {
            ...Formvalue,
            ["symbol"]: tokenDetails.data.tokenSymbol,
          };
          Formvalue = {
            ...Formvalue,
            ["decimals"]: parseInt(tokenDetails.data.tokenDecimal),
          };
          setFormvalue(Formvalue);
          let error = { ...errors };
          error[`${name}`] = "";
          setErrors(error);
        } else {
          Formvalue = {
            ...Formvalue,
            ["name"]: "",
          };
          Formvalue = { ...Formvalue, ["symbol"]: "" };
          Formvalue = {
            ...Formvalue,
            ["decimals"]: "",
          };
          setFormvalue(Formvalue);
          let error = { ...errors };
          error = { ...error, ["address"]: "Enter a valid token address" };
          setErrors(error);
        }
      }

      // let error = { ...errors };
      // error[`${name}`] = "";
      // setErrors(error);
    } catch (err) {
      console.log(err, "handleChange");
    }
  };

  const [selectedOption2, setSelectedOption2] = useState();

  const handleChange2 = async (e) => {
    const { name, value } = e;
    const tokenDetails = await UseToken(e.value, formvalue.address);
    // console.log(tokenDetails,e.value,"tokenDetails")
    let Formvalue = { ...formvalue };
    if (tokenDetails?.status == true) {
      Formvalue = { ...Formvalue, ["chainId"]: value };
      Formvalue = {
        ...Formvalue,
        ["name"]: tokenDetails.data.tokenName,
      };
      Formvalue = { ...Formvalue, ["symbol"]: tokenDetails.data.tokenSymbol };
      Formvalue = {
        ...Formvalue,
        ["decimals"]: parseInt(tokenDetails.data.tokenDecimal),
      };
      setFormvalue(Formvalue);
      setSelectedOption2(selectedOption2);
      let error = { ...errors };
      
      error['address'] = "";
      console.log(error,"error")
      setErrors(error);
    } else {
      let error = { ...errors };
      Formvalue = {
        ...Formvalue,
        ["name"]: "",
      };
      Formvalue = { ...Formvalue, ["symbol"]: "" };
      Formvalue = {
        ...Formvalue,
        ["decimals"]: "",
      };
      error = { ...error, ["address"]: "Enter a valid token address" };
      setErrors(error);
    }
  };

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => {
      return {
        ...styles,
        backgroundColor: isHovered
        ? "#DBBD7C"
        : isSelected
        ? "#917331"
        : isFocused
        ? "#DBBD7C"
        : "#000",
        cursor: "pointer",
        color: isHovered
          ? "#000"
          : isSelected
          ? "#000"
          : isFocused
          ? "#000"
          : "#fff",
        fontSize: "13px",
        zIndex: 1,
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "43px",
      padding: "0px 10px",
      backgroundColor: "transparent",
      color: "red",
      border: "1px solid #262626",
      borderRadius: 0,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "transparent",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "red",
      padding: "0px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#ffffff",
    }),
    menuList: (base) => ({
      ...base,

      padding: 0,
      width: "100%",

      borderRadius: 5,
      background: "transparent",
    }),

    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#fff",
      };
    },
  };

  const validation = (value) => {
    try {
      let error = { ...errors };
      var pattern = new RegExp(addressRegex);
      if (isEmpty(value.name)) {
        error["name"] = "Field is required";
      } else {
        error["name"] = "";
      }
      if (isEmpty(value.address)) {
        error["address"] = "Field is required";
      }
      // else if (isEmpty(value.address?.match(pattern))) {
      //   error["address"] = "Enter Token Valid Address";
      // }
      else {
        error["address"] = "";
      }
      if (isEmpty(value.symbol)) {
        error["symbol"] = "Field is required";
      } else {
        error["symbol"] = "";
      }
      if (isEmpty(value.decimals)) {
        error["decimals"] = "Field is required";
      } else {
        error["decimals"] = "";
      }
      if (isEmpty(value.chainId)) {
        error["chainId"] = "Field is required";
      } else {
        error["chainId"] = "";
      }
      if (isEmpty(value.logoURI)) {
        error["logoURI"] = "Field is required";
      } else {
        error["logoURI"] = "";
      }
      return error;
    } catch (err) {
      console.log(err, "validation__err");
    }
  };

  const handleSubmit = async () => {
    try {
      let isValidate = validation(formvalue);
      setErrors(isValidate);
      if (!ObjectIsempty(isValidate)) {
        return true;
      }
      let { status, message, result } = await addNewToken(formvalue);
      if (status == "success") {
        toast.success("Token Added Successfully");
        handleClose();
        getTokens();
        setSelectedOption2();
        setFormvalue({});
        setErrors({});
      } else {
        toast.error("Token Already Added");
        handleClose();
        getTokens();
        setSelectedOption2();
        setFormvalue({});
        setErrors({});
      }
      // handleClose();
      // getTokens();
    } catch (err) {
      console.log(err, "handleSubmit__err");
    }
  };

  const HandleClose = async () => {
    handleClose();
    setSelectedOption2();
    setFormvalue({});
    setErrors({});
  };

  return (
    <>
      <Modal
        centered
        className="cmn_modal"
        show={show}
        onHide={HandleClose}
        backdrop="static"
        keyboard={false}>
        <Modal.Body>
          <div className="cmn_modal_header d-flex justify-content-between align-items-center">
            <p className="cmn_modal_title">Add Token</p>
            <button
              className="cmn_modal_closer rounded-5"
              onClick={() => {
                HandleClose();
              }}>
              <i class="fa-solid fa-xmark" />
            </button>
          </div>

          <div className="rp_singleinput_holder mb-3">
            <p className="rp_label mb-2">Address</p>
            <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
              <input
                type="text"
                placeholder="Enter Token Address"
                name="address"
                // value={formvalue?.address}
                className="rp_singleInput flex-grow-1"
                onChange={handleChange}
              />
            </div>
            <span className="text-danger">{errors && errors.address}</span>
          </div>

          <div className="rp_singleinput_holder mb-3">
            <p className="rp_label mb-2">Chain Id</p>
            {/* <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2"> */}
            <Select
              onChange={(e) => handleChange2(e)}
              options={options}
              name="chainId"
              defaultValue="theme1"
              styles={stylesgraybg}
              isSearchable={false}
              classNamePrefix="customselect"
            />
          </div>
          <span className="text-danger">{errors && errors.chainId}</span>
          {/* </div> */}

          <div className="mt-4">
            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Name</p>
              <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                <input
                  type="text"
                  placeholder="Enter Token Name"
                  name="name"
                  value={formvalue.name}
                  className="rp_singleInput flex-grow-1"
                  onChange={handleChange}
                />
              </div>
              <span className="text-danger">{errors && errors.name}</span>
            </div>

            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Symbol</p>
              <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                <input
                  type="text"
                  placeholder="Enter Token Symbol"
                  name="symbol"
                  value={formvalue.symbol}
                  className="rp_singleInput flex-grow-1"
                  onChange={handleChange}
                />
              </div>
              <span className="text-danger">{errors && errors.symbol}</span>
            </div>

            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Decimals</p>
              <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                <input
                  type="text"
                  placeholder="Enter Token Decimals"
                  name="decimals"
                  readOnly
                  value={formvalue.decimals}
                  className="rp_singleInput flex-grow-1"
                  onChange={handleChange}
                />
              </div>
              <span className="text-danger">{errors && errors.decimals}</span>
            </div>

            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Logo URI</p>
              <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                <input
                  type="text"
                  name="logoURI"
                  placeholder="Enter Logo URI"
                  className="rp_singleInput flex-grow-1"
                  onChange={handleChange}
                />
              </div>
              <span className="text-danger">{errors && errors.logoURI}</span>
            </div>

            <button
              className="orange_small_primary mt-3"
              onClick={() => {
                handleSubmit();
              }}>
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddTokenModal;
