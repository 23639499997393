import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { adminForgotPassword } from "../actions/admin";
import { isEmpty, ObjectIsempty } from "../lib/isEmpty";
// import { useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { Images } from "../Images";

const initialFormValue = {
  email: "",
};

const ForgotPassword = () => {
  const [formvalue, setFormvalue] = useState(initialFormValue);
  const [errors, setErrors] = useState({});
  // const navigate = useNavigate();
  const history = useHistory();

  const handleChange = (e) => {
    try {
      const { name, value } = e.target;
      let Formvalue = { ...formvalue, [name]: value };
      setFormvalue(Formvalue);
      let error = { ...errors };
      error[`${name}`] = "";
      setErrors(error);
    } catch (err) {
      console.log(err, "handleChange");
    }
  };

  const validation = (value) => {
    try {
      let error = { ...errors };
      if (isEmpty(value.email)) {
        error["email"] = "Field is required";
      } else {
        error["email"] = "";
      }
      return error;
    } catch (err) {
      console.log(err, "validation__err");
    }
  };

  const handleSubmit = async () => {
    try {
      let email = formvalue.email;

      let isValidate = validation({ email: email });
      setErrors(isValidate);
      if (!ObjectIsempty(isValidate)) {
        return true;
      }
      let { status, message, result } = await adminForgotPassword({
        email: email,
      });
      if (status == "success") {
        toast.success("OTP Send To Your Email,Please Check");
        history.push({
          pathname: "/otpVerification",
          state: { datas: JSON.stringify(email) },
        });
        // toastAlert("success", "Pair Added Successfully");
      } else {
        toast.success("OTP Send Failed,Please Try Again");
      }
    } catch (err) {
      console.log(err, "handleSubmit__err");
    }
  };

  return (
    <>
      <Container fluid className="common_bg login_bg position-relative">
        <Row className="justify-content-center align-items-center row_card">
          <Col xl={6} lg={6} md={8} sm={12}>
            <div className="dashboard_box p-4">
              <div className="logo_sec d-flex justify-content-center">
                <img
                  src={Images.logo}
                  className="img-fluid main_logo"
                />
              </div>
              <div className="profile_holder mt-3">
                <p className="header_title_big">Forgot Password</p>
                <hr className="grey_hr" />
              </div>

              <div className="profile_holder">
                <Row>
                  <Col lg={12} md={12} sm={12} className="mb-4">
                    <div className="rp_singleinput_holder">
                      <p className="rp_label mb-2">Email Address</p>
                      <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                        <input
                          type="text"
                          placeholder="Enter Email Address"
                          name="email"
                          onChange={handleChange}
                          className="rp_singleInput flex-grow-1"
                        />
                      </div>
                      <span className="text-danger">{errors.email}</span>
                    </div>
                    <div className="mt-3 text-end">
                      <Link to="/" className="link_theme">
                        Back to Login?
                      </Link>
                    </div>
                  </Col>
                </Row>
                <div className="text-center">
                  {/* <NavLink to="/resetNewPassword" className="sidebar_links"> */}
                  <button
                    className="orange_small_primary"
                    onClick={() => {
                      handleSubmit();
                    }}>
                    Submit
                  </button>
                  {/* </NavLink> */}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ForgotPassword;
