import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Sidebar from '../Components/Sidebar'
import Header from '../Components/Header'
import { useEffect } from 'react'

function Profile() {

    useEffect(() =>{
        window.scroll(0,0)
      },[])
  return (
    <>
    <Container fluid className='common_bg position-relative'>
    <div className='liner'></div>
    <Row >
        <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
            <Sidebar/>
        </Col>
        <Col xl={10} lg={12} className='pe-3'>
            <Header title={'Profile'}/>
            <div className='mt-5'>
                <div className='text-end'>
                    <button className='orange_primary_btn'>Edit Profile</button>
                </div>
                <Row className='profile_holder'>
                    <Col lg={7} className='mb-4'>
                    <div className='rp_singleinput_holder'>
                    <p className='rp_label mb-2'>Name</p>
                    <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                        <input type='text' placeholder='Name' className='rp_singleInput flex-grow-1' />
                    </div>
                </div>
                    </Col>

                    <Col lg={7} className='mb-4'>
                    <div className='rp_singleinput_holder'>
                    <p className='rp_label mb-2'>Email</p>
                    <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                        <input type='email' placeholder='Email' className='rp_singleInput flex-grow-1' />
                    </div>
                </div>
                    </Col>

                   
                </Row>
                
            </div>
        </Col>
    </Row>

    </Container>
    </>
  )
}

export default Profile