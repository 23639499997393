import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import { isEmpty, ObjectIsempty } from "../lib/isEmpty";
import { EditUserStatus } from "../actions/admin";
import { getAllPlans } from "../actions/currency";
import toast from "react-hot-toast";

const initialFormValue = {
  name: "",
  status: "",
  walletAddress: "",
  plan: [],
};

const EditUsersModal = ({ show, handleClose, record, getUserAllList }) => {
  console.log(record, "record");

  const [formvalue, setFormvalue] = useState({});
  const [errors, setErrors] = useState({});
  const [plans, setPlans] = useState();
  const [options1, setOptions1] = useState();

  const getAllPlansList = async () => {
    try {
      let { status, message, result } = await getAllPlans();
      if (status == "success") {
        setPlans(result);
        setOptions1(result);
      }
    } catch (err) {}
  };
  useEffect(() => {
    setFormvalue(record);
    getAllPlansList();
  }, [record]);

  options1?.map((val) => {
    val["label"] = val.name;
    val["value"] = val.name;
  });

  console.log(options1, formvalue?.plan, "options111111111");

  const handleChange = (e) => {
    try {
      const { name, value } = e.target;
      let Formvalue = { ...formvalue, [name]: value };
      setFormvalue(Formvalue);
      setErrors(validation(Formvalue));
    } catch (err) {
      console.log(err, "handleChange");
    }
  };

  const validation = (value) => {
    try {
      let error = errors;
      if (isEmpty(value.name)) {
        error["name"] = "Field is required";
      } else {
        error["name"] = "";
      }
      if (isEmpty(value.walletAddress)) {
        error["walletAddress"] = "Field is required";
      } else {
        error["walletAddress"] = "";
      }
      if (isEmpty(value.status)) {
        error["status"] = "Field is required";
      } else {
        error["status"] = "";
      }
      if (isEmpty(value.plan)) {
        error["plan"] = "Field is required";
      } else {
        error["plan"] = "";
      }
      return error;
    } catch (err) {
      console.log(err, "validation__err");
    }
  };

  const handleSubmit = async () => {
    try {
      let changeData = {
        _id: record?._id,
        name: formvalue?.name,
        status: formvalue?.status,
        plan: formvalue?.plan[0]?._id,
      };
      // let isValidate = validation(formvalue);
      // console.log(isValidate, !ObjectIsempty(isValidate), "isValidate+++++++");
      // setErrors(isValidate);
      // if (!ObjectIsempty(isValidate)) {
      //   return true;
      // }
      let { status, message, result } = await EditUserStatus(changeData);
      if (status == "success") {
        toast.success("User Updated Successfully");
        handleClose();
        getUserAllList();
      } else {
        toast.success("User Updated Failed");
        handleClose();
        getUserAllList();
      }
      handleClose();
      getUserAllList();
    } catch (err) {
      console.log(err, "handleSubmit__err");
    }
  };

  const options = [
    { value: true, label: "Active" },
    { value: false, label: "InActive" },
  ];

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => {
      return {
        ...styles,
        backgroundColor: isHovered
        ? "#DBBD7C"
        : isSelected
        ? "#917331"
        : isFocused
        ? "#DBBD7C"
        : "#000",
        cursor: "pointer",
        color: isHovered
          ? "#000"
          : isSelected
          ? "#000"
          : isFocused
          ? "#000"
          : "#fff",
        fontSize: "13px",
        zIndex: 1,
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "43px",
      padding: "0px 10px",

      backgroundColor: "transparent",
      color: "red",
      border: "none",
      borderRadius: 0,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "transparent",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "red",
      padding: "0px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#ffffff",
    }),
    menuList: (base) => ({
      ...base,

      padding: 0,
      width: "100%",

      borderRadius: 5,
      background: "transparent",
    }),

    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#fff",
      };
    },
  };

  const [selectedOption1, setSelectedOption1] = useState();
  const [selectedOption2, setSelectedOption2] = useState();

  const handleChange1 = (selectedOption) => {
    setSelectedOption1(selectedOption);
    // console.log(selectedOption1, "selectedOption1");
    let Formvalue = { ...formvalue, ["status"]: selectedOption.value };
    setFormvalue(Formvalue);
  };

  const handleChange2 = (e) => {
    setSelectedOption2(e);
    let Formvalue = { ...formvalue, ["plan"]: [e] };
    setFormvalue(Formvalue);
    console.log(selectedOption2, "selectedOption2");
  };
  const HandleClose = () => {
    handleClose();
    setFormvalue({});
    setErrors({});
  };
  return (
    <>
      <Modal
        centered
        className="cmn_modal"
        show={show}
        onHide={HandleClose}
        backdrop="static"
        keyboard={false}>
        <Modal.Body>
          <div className="cmn_modal_header d-flex justify-content-between align-items-center">
            <p className="cmn_modal_title">Edit User</p>
            <button
              className="cmn_modal_closer rounded-5"
              onClick={() => {
                HandleClose();
              }}>
              <i class="fa-solid fa-xmark" />
            </button>
          </div>

          <div className="mt-4">
            {/* <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Name</p>
              <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                <input
                  type="text"
                  placeholder="Name"
                  className="rp_singleInput flex-grow-1"
                />
              </div>
            </div> */}

            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Name</p>
              <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                <input
                  type="text"
                  placeholder="Enter User Name"
                  className="rp_singleInput flex-grow-1"
                  value={formvalue?.name}
                  name="name"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Wallet Address</p>
              <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                <input
                  type="text"
                  placeholder="Enter Wallet Address"
                  className="rp_singleInput flex-grow-1"
                  value={formvalue?.walletAddress}
                  readOnly
                />
              </div>
            </div>
            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Status</p>
              <div className="rp_input_holder rounded-2 px-0">
                <Select
                  onChange={(e) => handleChange1(e)}
                  options={options}
                  defaultValue={formvalue?.status}
                  styles={stylesgraybg}
                  isSearchable={false}
                  classNamePrefix="customselect"
                  value={
                    formvalue &&
                    options.find((val) => val?.value == formvalue?.status)
                  }
                  // defaultInputValue="Theme 1"
                  // menuIsOpen
                />
              </div>
            </div>

            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Plan</p>
              <div className="rp_input_holder rounded-2 px-0">
                <Select
                  value={
                    formvalue?.plan?.length > 0 &&
                    options1?.find((val) => val?.name == formvalue?.plan[0]?.name)
                  }
                  onChange={(e) => handleChange2(e)}
                  options={options1}
                  // defaultValue="theme1"
                  styles={stylesgraybg}
                  isSearchable={false}
                  classNamePrefix="customselect"

                  // defaultInputValue="Theme 1"
                  // menuIsOpen
                />
              </div>
            </div>

            <button
              className="orange_small_primary mt-3"
              onClick={() => {
                handleSubmit();
              }}>
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditUsersModal;
