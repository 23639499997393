import React, { Component, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import browser from "browser-detect";
import { UseDispatch, useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { login, getGeoInfoData } from "../actions/admin";
import { toastAlert } from "../lib/toastAlert";
import { isEmpty, ObjectIsempty } from "../lib/isEmpty";
import toast from "react-hot-toast";
import { Images } from "../Images";

const initialFormValue = {
  email: "",
  password: "",
  twoFACode: "",
  isTerms: false,
};

const LoginPage = () => {
  const [errors, setErrors] = useState({});
  const [showTwoFA, setshowTwoFA] = useState(false);
  const [formvalue, setformvalue] = useState(initialFormValue);
  const [loginHistory, setLoginHistory] = useState({});
  const [ispassword, setIsPassword] = useState(true);
  const { email, password, twoFACode, isTerms } = formvalue;
  const [pwd, setPwd] = useState(false);

  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const history = useHistory();

  const getGeoInfo = async () => {
    try {
      let { result } = await getGeoInfoData();

      const browserResult = browser();
      let loginHistory = {
        countryName: result.country_name,
        countryCode: result.country_calling_code,
        ipaddress: result.ip,
        region: result.region,
        broswername: browserResult.name,
        ismobile: browserResult.mobile,
        os: browserResult.os,
      };
      setLoginHistory(loginHistory);
    } catch (err) {}
  };

  // componentWillReceiveProps(nextProps) {
  //     if (nextProps.auth.isAuthenticated) {
  //         this.props.history.push("/dashboard");
  //     }

  //     if (nextProps.errors) {
  //         this.setState({
  //             errors: nextProps.errors
  //         });
  //     }
  // }

  // onChange = e => {
  //     this.setState({ [e.target.id]: e.target.value });
  // };

  const validation = (value) => {
    try {
      let error = { ...errors };
      if (isEmpty(value.email)) {
        error["email"] = "Field is required";
      } else {
        error["email"] = "";
      }
      if (isEmpty(value.password)) {
        error["password"] = "Field is required";
      } else {
        error["password"] = "";
      }
      return error;
    } catch (err) {
      console.log(err, "validation__err");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // this.setState({ [e.target.id]: e.target.value });
    if (name == "twoFACode") {
      if (!(value == "" || (/^[0-9\b]+$/.test(value) && value.length <= 6))) {
        return;
      }
    }
    // this.setState({...this.state.formvalue,...{[name]:value}})
    // this.setState({
    let Formvalue = { ...formvalue, ...{ [name]: value } };
    let error = { ...errors };
    error[`${name}`] = "";
    setformvalue(Formvalue);
    setErrors(error);
    // });
  };

  const onSubmit = async (e) => {
    try {
      const { email, password, twoFACode, isTerms } = formvalue;
      const reqData = {
        email: email,
        password: password,
        twoFACode: twoFACode,
        loginHistory: loginHistory,
      };
      let isValidate = validation(formvalue);
      setErrors(isValidate);
      if (!ObjectIsempty(isValidate)) {
        return true;
      }
      const { status, loading, message, error } = await login(
        reqData,
        dispatch
      );
      if (status == "success") {
        history.push("/dashboard");
        toast.success("Login Successfully");
      } else {
        toast.error("Password Incorrect");
      }
      if (status == "TWO_FA") {
        setshowTwoFA(true);
      }
    } catch (err) {}
  };

  useEffect(() => {
    getGeoInfo();
  }, []);

  return (
    <>
      <Container fluid className="common_bg login_bg position-relative">
        <Row className="justify-content-center align-items-center row_card">
          <Col xl={6} lg={6} md={8} sm={12}>
            <div className="dashboard_box p-4">
              <div className="logo_sec d-flex justify-content-center">
                <img
                  src={Images.logo}
                  className="img-fluid main_logo"
                />
              </div>
              <div className="profile_holder mt-3">
                <p className="header_title_big">Login</p>
                <hr className="grey_hr" />
              </div>

              <div className="profile_holder">
                <Row>
                  <Col lg={12} md={12} sm={12} className="mb-4">
                    <div className="rp_singleinput_holder">
                      <p className="rp_label mb-2">Email Address</p>
                      <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                        <input
                          type="text"
                          placeholder="Enter Email Address"
                          name="email"
                          onChange={handleChange}
                          className="rp_singleInput flex-grow-1"
                        />
                      </div>
                      <span className="text-danger">{errors.email}</span>
                    </div>
                  </Col>

                  <Col lg={12} md={12} sm={12} className="mb-4">
                    <div className="rp_singleinput_holder">
                      <p className="rp_label mb-2">Password</p>
                      <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                        <input
                          type={pwd ? "text" : "password"}
                          placeholder="Enter Password"
                          name="password"
                          onChange={handleChange}
                          className="rp_singleInput flex-grow-1"
                        />
                        <i
                          class={
                            pwd ? "fa-solid fa-eye-slash" : "fa-solid fa-eye"
                          }
                          onClick={() => setPwd(!pwd)}
                        />
                      </div>
                      <span className="text-danger">{errors.password}</span>
                    </div>
                    <div className="mt-3 text-end">
                      <Link to="/forgotpassword" className="link_theme">
                        Forgot Password?
                      </Link>
                    </div>
                  </Col>
                </Row>
                <div className="text-center">
                  <button
                    className="orange_small_primary"
                    onClick={() => {
                      onSubmit();
                    }}>
                    Submit
                  </button>

                  {/* <NavLink
                    to="/dashboard"
                    className="orange_small_primary sidebar_links">
                    Submit
                  </NavLink> */}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LoginPage;
